import React from 'react';
import './LectureDetailPopup.scss';
import { MdClose } from 'react-icons/md';
import { lectureVideoItem } from '../LectureVideoList/lectureVideoData';
import ReactPlayer from 'react-player';

type LectureDetailPopupProps = {
  lectureInfo?: lectureVideoItem;
  isShowPopup: boolean;
  onClickClosePopup(): void;
};

const LectureDetailPopup = ({ lectureInfo, isShowPopup, onClickClosePopup }: LectureDetailPopupProps) => {
  if (!isShowPopup) return null;
  if (!lectureInfo) return null;

  return (
    <div className={`LectureDetailPopup ${isShowPopup ? 'active' : ''}`} style={{ display: 'flex !important' }}>
      <div className='popup-inner'>
        <div className='title'>{lectureInfo?.title}</div>
        <div className='close-btn'>
          <MdClose onClick={onClickClosePopup} />
        </div>
        <ReactPlayer url={lectureInfo?.videoUrl} width='100%' height='100%' controls={true} />
      </div>
    </div>
  );
};

export default LectureDetailPopup;
