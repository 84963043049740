import React, { useCallback, useEffect, useState } from 'react';
import './OrderList.scss';
import { OrderItem } from '../OrderItem';
import Pagination from 'rc-pagination';
import usePagination from '../../../hooks/usePagination';
import useIsLoading from 'hooks/useIsLoading';
import { SchoolApi } from 'lib/api';
import { OrderInfo } from 'types';

export type OrderListProps = {
  email?: string;
  showList: string;
};

const OrderList = ({ email, showList }: OrderListProps) => {
  const { offset, currentPage, setOffset, setCurrentPage } = usePagination('order_pagination');
  const { isLoading, setIsLoading } = useIsLoading();

  const [orderList, setOrderList] = useState<OrderInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const limit = 3;

  const loadOrderList = useCallback(async () => {
    if (!email) return;
    try {
      const res = await SchoolApi.get(`/v1.1/orders?offset=${offset}&limit=${limit}&isPayment=${true}&email=${email}`);
      setOrderList(res.data.data.orderList);
      setTotalCount(res.data.data.totalCount);
    } catch (error) {}
    setIsLoading(false);
  }, [email, offset, setIsLoading]);

  useEffect(() => {
    loadOrderList();
  }, [loadOrderList]);

  const onClickPage = (page: number) => {
    setOffset(Math.ceil((page - 1) * limit));
    setCurrentPage(page);
  };

  if (showList !== 'order') return null;
  if (isLoading) return <div className='OrderListNotFound'>로딩중...</div>;
  if (!orderList.length) return <div className='OrderListNotFound'>주문서 내역이 없습니다.</div>;
  return (
    <div className='OrderList'>
      <table className='order-list-table'>
        <colgroup>
          <col width='15%' />
          <col width='15%' />
          <col width='40%' />
          <col width='15%' />
          <col width='15%' />
        </colgroup>
        <thead>
          <tr>
            <th>주문일자</th>
            <th>주문번호</th>
            <th>상품정보</th>
            <th>결제금액</th>
            <th>진행상황</th>
          </tr>
        </thead>
        <tbody>
          {orderList.map((orderInfo) => (
            <OrderItem key={orderInfo.orderId} orderInfo={orderInfo} />
          ))}
        </tbody>
      </table>
      <Pagination
        className='pagination'
        total={Math.ceil(totalCount / limit) * limit}
        current={currentPage}
        onChange={onClickPage}
        pageSize={limit}
        prevIcon={'<'}
        nextIcon={'>'}
        jumpPrevIcon={'<<'}
        jumpNextIcon={'>>'}
      />
    </div>
  );
};

export default OrderList;
