import React from 'react';
import './LectureVideoList.scss';
import {
  lectureVideoItem,
  firstLectureVideoList,
  secondLectureVideoList,
  thirdLectureVideoList,
  fourthLectureVideoList,
} from './lectureVideoData';
import { LectureVideoItem } from '../LectureVideoItem';

type MyStudentListProps = {
  showList: string;
  onClickLecture(lectureInfo: lectureVideoItem): void;
};

const LectureVideoList = ({ showList, onClickLecture }: MyStudentListProps) => {
  if (showList !== 'lecture') return null;
  return (
    <div className='LectureVideoList'>
      <h2 className='lecture-subject'>컴퓨터사고력 이해</h2>
      <div className='lecture-list'>
        {firstLectureVideoList.map((lectureInfo, i) => (
          <LectureVideoItem lectureInfo={lectureInfo} onClickLecture={onClickLecture} key={`lecture-${i}`} />
        ))}
      </div>

      <h2 className='lecture-subject'>[엔트리] 프로그래밍 요소와 구조</h2>
      <div className='lecture-list'>
        {secondLectureVideoList.map((lectureInfo, i) => (
          <LectureVideoItem lectureInfo={lectureInfo} onClickLecture={onClickLecture} key={`lecture-${i}`} />
        ))}
      </div>

      <h2 className='lecture-subject'>우리가 만드는 VR WORLD</h2>
      <div className='lecture-list'>
        {thirdLectureVideoList.map((lectureInfo, i) => (
          <LectureVideoItem lectureInfo={lectureInfo} onClickLecture={onClickLecture} key={`lecture-${i}`} />
        ))}
      </div>

      <h2 className='lecture-subject'>VR 코딩</h2>
      <div className='lecture-list'>
        {fourthLectureVideoList.map((lectureInfo, i) => (
          <LectureVideoItem lectureInfo={lectureInfo} onClickLecture={onClickLecture} key={`lecture-${i}`} />
        ))}
      </div>
    </div>
  );
};

export default LectureVideoList;
