export type lectureVideoItem = {
  subject: string;
  title: string; //강의 영상 제목
  period: string; //차시
  thumbnailUrl: string;
  videoUrl: string;
  desc?: string[];
};

export const firstLectureVideoList: lectureVideoItem[] = [
  {
    subject: '컴퓨터사고력 이해',
    title: '생활 속 소프트웨어',
    period: '1차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/1.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_1%EB%8B%A8%EC%9B%90_1%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '컴퓨터사고력 이해',
    title: '절차적 문제해결',
    period: '2차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/2.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_1%EB%8B%A8%EC%9B%90_2%EC%B0%A8%EC%8B%9C.mp4',
  },
];

export const secondLectureVideoList: lectureVideoItem[] = [
  {
    subject: '[엔트리] 프로그래밍 요소와 구조',
    title: '엔트리 가입하기',
    period: '3차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/3.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_2%EB%8B%A8%EC%9B%90_1%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '[엔트리] 프로그래밍 요소와 구조',
    title: '기초 프로그래밍 체험',
    period: '4차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/4.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_2%EB%8B%A8%EC%9B%90_2%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '[엔트리] 프로그래밍 요소와 구조',
    title: '애니메이션',
    period: '5차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/5.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_2%EB%8B%A8%EC%9B%90_3%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '[엔트리] 프로그래밍 요소와 구조',
    title: '입력, 처리, 출력',
    period: '6차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/6.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_2%EB%8B%A8%EC%9B%90_4%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '[엔트리] 프로그래밍 요소와 구조',
    title: '숫자, 선택, 반복',
    period: '7차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/7.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_2%EB%8B%A8%EC%9B%90_5%EC%B0%A8%EC%8B%9C.mp4',
  },
];

export const thirdLectureVideoList: lectureVideoItem[] = [
  {
    subject: '우리가 만드는 VR WORLD',
    title: '따뜻한 VR 세상 만나기',
    period: '8차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/8.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_1%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['VR의 개념 이해', 'VRWARE 소개 및 가입'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '비주얼 씽킹으로 만드는 캐릭터',
    period: '9차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/9.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_2%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['가상공간에 캐릭터 객체 배치'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '비주얼 씽킹으로 구성하는 가상공간',
    period: '10차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/10.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_3%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['구글 어스 활용 가상공간 지형 만들기'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '퀴즈와 연계한 디자인 씽킹',
    period: '11차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/11.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_4%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['재미있는 학습 이벤트 활용(1)'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '이미지와 동영상을 활용한 디자인 씽킹',
    period: '12차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/12.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_5%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['재미있는 학습 이벤트 활용(2)'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '가상공간을 넘나드는 순간이동',
    period: '13차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/13.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_6%EC%B0%A8%EC%8B%9C.mp4',
    desc: ['재미있는 학습 이벤트 활용(3)'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '도전! 나도 VRWARE Maker',
    period: '14차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/14.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_7%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '그림판 3D를 활용한 VR WORLD',
    period: '15차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/15.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_3%EB%8B%A8%EC%9B%90_8%EC%B0%A8%EC%8B%9C_%EC%88%98%EC%A0%95.mp4',
    desc: ['직접 만든 3D 오브젝트 추가하여 가상공간 꾸미기'],
  },
  {
    subject: '우리가 만드는 VR WORLD',
    title: '카드보드 만들기',
    period: 'Tip!',
    thumbnailUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/%EC%B9%B4%EB%93%9C%EB%B3%B4%EB%93%9C%20%EB%A7%8C%EB%93%A4%EA%B8%B0.png',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%EC%B9%B4%EB%93%9C%EB%B3%B4%EB%93%9C%20%EB%A7%8C%EB%93%A4%EA%B8%B0.mp4',
  },
];

export const fourthLectureVideoList: lectureVideoItem[] = [
  {
    subject: 'VR 코딩',
    title: 'VRWARE School과 코딩의 운명적 만남 : 마이크로 비트',
    period: '16차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/16.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_4%EB%8B%A8%EC%9B%90_1%EC%B0%A8%EC%8B%9C.mp4',
  },
  {
    subject: 'VR 코딩',
    title: 'VRWARE School과 코딩의 운명적 만남 : 지니봇',
    period: '17차시',
    thumbnailUrl: 'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/lecture/17.jpg',
    videoUrl:
      'https://api-storage.cloud.toast.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/videos/lecture/%ED%95%99%EC%83%9D%EC%9A%A9_%EA%B2%BD%EC%82%B0_4%EB%8B%A8%EC%9B%90_2%EC%B0%A8%EC%8B%9C.mp4',
  },
];
