import React from 'react';
import { OrderItemProps } from '../OrderItem/OrderItem';
import { dateFormat } from 'lib/common/dateFormat';
import priceFormat from '../../../lib/common/priceFormat';
import { OrderProductInfo } from 'types';
import { Link } from 'react-router-dom';
import './MobileOrderItem.scss';

export const MobileOrderItem = ({ orderInfo }: OrderItemProps) => {
  return (
    <div className='MobileOrderItem'>
      <div className='order-top'>
        <span className='order-product-name'>{returnProductName(orderInfo?.products)}</span>
        <div className='order-seq'>
          <Link className='order-detail-link' to={`/mypage/order/detail/${orderInfo?.orderSeq}`}>
            <span>{`상세보기 >`}</span>
            {orderInfo?.orderSeq}
          </Link>
        </div>
      </div>
      <div className='order-detail'>
        <div className='order-thumbnail'>
          <img src={returnProductThumbnail(orderInfo?.products)} alt='product-thumbnail' />
        </div>
        <div className='order-info'>
          <div className='order-date'>결제일 | {dateFormat(orderInfo?.paymentDate, 'yyyy.MM.dd')}</div>
          <div>수량 | {orderInfo?.totalOrderCount}</div>
          <div className='order-amt'>{priceFormat(orderInfo?.totalAmt)}원</div>
          <div>배송비 | {priceFormat(orderInfo?.deliveryFeeAmt)}원</div>
          <div className='order-progress'>{orderInfo?.orderStatusDesc}</div>
        </div>
      </div>
    </div>
  );
};

const returnProductName = (productList?: OrderProductInfo[]) => {
  if (!productList) return;

  if (productList.length > 1) {
    return `${productList[0].productName} 외 ${productList.length - 1}`;
  } else {
    return `${productList[0].productName}`;
  }
};

const returnProductThumbnail = (productList?: OrderProductInfo[]) => {
  if (!productList) return;

  return productList[0].productThumbnail;
};
