import React from 'react';
import './NoLicenseList.scss';
import { no_license } from 'images/MyPage';
const NoLicenseList = () => {
  return (
    <div className='NoLicenseList'>
      <img src={no_license} alt='no_license' />
      <p>사용중인 라이선스가 없어요!</p>
      <p>새로운 라이선스를 구매해보세요.</p>
      <a href='https://school.vrware.us/pricing'>
        <button>구매하기</button>
      </a>
    </div>
  );
};

export default NoLicenseList;
