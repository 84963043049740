import React from 'react';
import { lectureVideoItem } from '../LectureVideoList/lectureVideoData';
import './LectureVideoItem.scss';

type LectureVideoItemProps = {
  lectureInfo: lectureVideoItem;
  onClickLecture(lectureInfo: lectureVideoItem): void;
};

const LectureVideoItem = ({ lectureInfo, onClickLecture }: LectureVideoItemProps) => {
  return (
    <div className='LectureVideoItem' onClick={() => onClickLecture(lectureInfo)}>
      <div className='lecture-thumb' style={{ backgroundImage: `url(${lectureInfo.thumbnailUrl})` }} />
      <div className='lecture-bottom'>
        <div className='lecture-period'>{lectureInfo.period}</div>
        <div className='lecture-bottom-left'>{lectureInfo.title}</div>
        <div className='lecture-bottom-right'>
          {lectureInfo.desc?.map((descTxt) => (
            <p>- {descTxt}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LectureVideoItem;
