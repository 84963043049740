import React, { useEffect, useCallback } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { NotFoundPage } from 'pages/error';
import useAuthRequest from 'hooks/api/useAuthRequest';
import { VrwareApi } from 'lib/api';
import { login, logout } from 'hooks/useSession';
import { IntroPage } from 'pages/IntroPage';
import { Main } from 'pages/MainPage';
import { DownloadPage } from 'pages/DownloadPage';
import EduCasePage from 'pages/EduCasePage';
import MyClassPage from 'pages/MyClassPage';
import MyPagePage from 'pages/MyPagePage';
import MyClassDetailPage from 'pages/MyClassDetailPage';

import queryString from 'query-string';
import { useAtom } from 'jotai';
import {  selectAtom } from 'lib/common/atoms';

const App = () => {
  const { data, error } = useAuthRequest(`/v1.1/sessions/cookies`);
  const localSotrageUser = localStorage.getItem('globe_user');

// querystring 추가 
const queryLang = queryString.parse(window.location.search);
const [option, setOption] = useAtom(selectAtom); 

if(queryLang.hl){
  let hl = '';

  if(queryLang.hl == 'en'){
    hl = 'en';
  }else if(queryLang.hl == 'ar'){
    hl = 'ar';
  }else{
    hl = 'ko'
  }

  setOption(hl);

}


  const loadMemberFormServer = useCallback(
    async (email : any) => {
      try {
        const memberResp = await VrwareApi.get('/members?email=' + email);

        if (memberResp.data.status_code === 200) {
          const memberInfo = memberResp.data.data.memberInfo[0];

          //localstorage에 유저 정보가 있는데 로그인한 정보와 이메일이 다르다면 로그아웃
          if (localSotrageUser && JSON.parse(localSotrageUser).email !== memberInfo.email) {
            return logout();
          }

          return login(memberInfo);
        }
      } catch (error : any) {
        if (error.response.data.status_code >= 400) {
          return logout();
        }
      }
    },
    [localSotrageUser],
  );

  function getCookie(key: string) {
    let result = '';
    let cookie = document.cookie.split(';');
    cookie.some(function (item) {
        // 공백을 제거
        item = item.replace(' ', '');

        let dic = item.split('=');
        if (key === dic[0]) {
            result = dic[1];
            return true;    // break;
        }
    });
    return result;
}

useEffect(() => {
  const hl = getCookie('hl');
  console.log("app" + hl);
});

  useEffect(() => {
    if (error) {
      return logout();
    }
    if (!data) return;

    const email = data.data.email;
    loadMemberFormServer(email);
  }, [data, error, loadMemberFormServer]);




  
  return (
    <Router>
      <Switch>
      <Route exact path='/' component={Main}/>
        <Route path='/edu-case' component={EduCasePage} />
        <Route exact path='/myclass' component={MyClassPage} />
        <Route exact path='/myclass/:classCode' component={MyClassDetailPage} />
        <Route path='/download' component={DownloadPage} />
        <Route path='/intro' component={IntroPage} />
        <Route exact path='/mypage' component={MyPagePage} />
        <Route exact path='/mypage/:showList' component={MyPagePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
