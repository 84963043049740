import React, { useEffect, useState } from 'react';
import './MyPageList.scss';
import { NavLink } from 'react-router-dom';

import { LicenseInfo, MyStudents } from 'types';

type MyPageListProps = {
  licenseList: LicenseInfo[];
  myStudentList: MyStudents[];
};

const MyPageList = ({ licenseList, myStudentList }: MyPageListProps) => {
  const [isEnableLecture, setIsEnableLecture] = useState(false);

  useEffect(() => {
    for (let i = 0; i < licenseList.length; i++) {
      const licenseInfo = licenseList[i];
      if (licenseInfo.type === 'school' && licenseInfo.licenseGrade !== 'lite' && licenseInfo?.isExpired === false) {
        return setIsEnableLecture(true);
      }
    }
  }, [licenseList]);

  return (
    <div className='MyPageList'>
      <div className='mypage-list-select'>
        <NavLink to={'/mypage/license'} className='mypage-select' activeClassName='select-active'>
          라이선스 내역 조회
        </NavLink>
        <NavLink to='/mypage/billing' className='mypage-select' activeClassName='select-active'>
          정기 결제 조회
        </NavLink>
        <NavLink to='/mypage/order' className='mypage-select' activeClassName='select-active'>
          주문 내역 조회
        </NavLink>
        {isEnableLecture && (
          <NavLink to='/mypage/lecture' className='mypage-select' activeClassName='select-active'>
            학습 영상
          </NavLink>
        )}
        {myStudentList.length >= 1 && (
          <NavLink to='/mypage/my-students' className='mypage-select' activeClassName='select-active'>
            학생계정 리스트
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default MyPageList;
