import useSWR from 'swr';

export default function usePagination(KEY: string) {
  const { data: currentPage = 1, mutate: mutatePage } = useSWR<number>(KEY + '_page');
  const { data: offset = 0, mutate: mutateOffset } = useSWR<number>(KEY + '_offset');

  const setCurrentPage = (page: number) => {
    mutatePage(page);
  };

  const setOffset = (offset: number) => {
    mutateOffset(offset);
  };

  return {
    currentPage,
    setCurrentPage,
    offset,
    setOffset,
  };
}
