import React, { useState, useCallback, useEffect } from 'react';
import './MobileOrderList.scss';
import usePagination from 'hooks/usePagination';
import useIsLoading from 'hooks/useIsLoading';
import { SchoolApi } from 'lib/api';
import Pagination from 'rc-pagination';
import { MobileOrderItem } from '../MobileOrderItem/MobileOrderItem';
import { OrderInfo } from 'types';
import { OrderListProps } from '../OrderList/OrderList';

export const MobileOrderList = ({ email, showList }: OrderListProps) => {
  const { offset, currentPage, setOffset, setCurrentPage } = usePagination('order_pagination');
  const { isLoading, setIsLoading } = useIsLoading();

  const [orderList, setOrderList] = useState<OrderInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const limit = 3;

  const loadOrderList = useCallback(async () => {
    if (!email) return;
    try {
      const res = await SchoolApi.get(`/v1.1/orders?offset=${offset}&limit=${limit}&isPayment=${true}&email=${email}`);
      setOrderList(res.data.data.orderList);
      setTotalCount(res.data.data.totalCount);
    } catch (error) {}
    setIsLoading(false);
  }, [email, offset, setIsLoading]);

  useEffect(() => {
    loadOrderList();
  }, [loadOrderList]);

  const onClickPage = (page: number) => {
    setOffset(Math.ceil((page - 1) * limit));
    setCurrentPage(page);
  };

  if (showList !== 'order') return null;
  if (isLoading) return <div className='OrderListNotFound'>로딩중...</div>;
  if (!orderList) return <div className='OrderListNotFound'>주문서 내역이 없습니다.</div>;
  return (
    <div className='MobileOrderList'>
      {orderList.map((orderInfo) => (
        <MobileOrderItem key={orderInfo.orderId} orderInfo={orderInfo} />
      ))}
      <Pagination
        className='pagination'
        total={Math.ceil(totalCount / limit) * limit}
        current={currentPage}
        onChange={onClickPage}
        pageSize={limit}
        prevIcon={'<'}
        nextIcon={'>'}
        jumpPrevIcon={''}
        jumpNextIcon={''}
      />
    </div>
  );
};
