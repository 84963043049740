import React, { useCallback, useEffect, useState } from 'react';
import { MyPageTemplate } from '../components/myPage/MyPageTemplate';
import { MyPageTop } from '../components/myPage/MyPageTop';
import { MyPageList } from '../components/myPage/MyPageList';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import LicenseCodeUsed from '../components/myPage/LicenseCodeUsed';
import { OrderList } from '../components/myPage/OrderList';
import { LicenseCode } from '../components/myPage/LicenseCode';
import useSession from '../hooks/useSession';
import { MyLicenseList } from '../components/myPage/MyLicenseList';
import useMember from 'hooks/useMember';
import { useParams, useHistory } from 'react-router-dom';
import { MobileOrderList } from 'components/myPage/MobileOrderList/MobileOrderList';
import { SchoolApi, VrwareApi } from 'lib/api';
import { LicenseInfo, MyStudents } from 'types';
import { MyStudentList } from 'components/myPage/MyStudentList';
import { LectureVideoList } from 'components/myPage/LectureVideoList';
import { lectureVideoItem } from 'components/myPage/LectureVideoList/lectureVideoData';
import { LectureDetailPopup } from 'components/myPage/LectureDetailPopup';
import { useAtom } from 'jotai';
import { selectAtom } from '../lib/common/atoms';


const MyPagePage = () => {
  const { isSession } = useSession();
  const { memberInfo, email } = useMember();
  let { showList } = useParams<{ showList: string }>();
  const history = useHistory();
  const [licenseList, setLicenseList] = useState<LicenseInfo[]>([]);
  const [myStudentList, setMyStudentList] = useState<MyStudents[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [lectureInfo, setLectureInfo] = useState<lectureVideoItem>();

  const onClickLecture = (_lectureInfo: lectureVideoItem) => {
    setIsShowPopup(!isShowPopup);
    setLectureInfo(_lectureInfo);
  };

  const onClickClosePopup = () => {
    setIsShowPopup(false);
    setLectureInfo({ subject: '', title: '', period: '', thumbnailUrl: '', videoUrl: '' });
  };

  const loadLicenseList = useCallback(async () => {
    if (!email) return;

    try {
      const res = await VrwareApi(`/v1.1/licenses?email=${email}&licenseType=storybuilder`);
      setLicenseList(res.data.data.licenseList);
      setIsLoading(false);
    } catch (error) {}
  }, [email]);

  const loadMyStudentList = useCallback(async () => {
    if (!email) return;

    try {
      const res = await SchoolApi(`/v1.0/my-students/${email}`);
      setMyStudentList(res.data.data.myStudentList);
    } catch (error) {}
  }, [email]);

  useEffect(() => {
    loadLicenseList();
    loadMyStudentList();
  }, [loadLicenseList, loadMyStudentList]);

  useEffect(() => {
    if (!isSession) {
      history.push('/');
      return alert('로그인 후 이용해 주세요.');
    }
    if (isSession && !showList) history.push('/mypage/license');
  }, [history, isSession, showList]);

  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };



  return (
    <MyPageTemplate header={<Header option={option} changeOption={onChangeOption}/>} footer={<Footer option={option}/>}>
      <MyPageTop nickname={memberInfo?.nickname} />
      <div className='container'>
        <MyPageList licenseList={licenseList} myStudentList={myStudentList} />
        <MyLicenseList email={email} showList={showList} licenseList={licenseList} isLoading={isLoading} />
        <LicenseCode email={email} showList={showList} />
        <LicenseCodeUsed email={email} showList={showList} />
        <OrderList email={email} showList={showList} />
        <MobileOrderList email={email} showList={showList} />
        <MyStudentList email={email} showList={showList} myStudentList={myStudentList} />
        <LectureVideoList showList={showList} onClickLecture={onClickLecture} />
        <LectureDetailPopup lectureInfo={lectureInfo} isShowPopup={isShowPopup} onClickClosePopup={onClickClosePopup} />
      </div>
    </MyPageTemplate>
  );
};

export default MyPagePage;
