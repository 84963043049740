import { AuthApi } from 'lib/api';
import React, { useState } from 'react';
import { MyStudents } from '../../../types';
import './MyStudentList.scss';

type MyStudentListProps = {
  email?: string;
  showList: string;
  myStudentList: MyStudents[];
};

const MyStudentList = ({ email, showList, myStudentList }: MyStudentListProps) => {
  const [isShowPwd, setIsShowPwd] = useState(false);
  const [pwd, setPwd] = useState<string>('');

  const checkSession = async () => {
    try {
      const res = await AuthApi.post(
        '/v1.1/sessions',
        { email: email, password: pwd, saveSessionState: true },
        { withCredentials: true },
      );
      if (res.data.status_code === 200) {
        setIsShowPwd(true);
        alert('비밀번호가 일치합니다. 공통 비밀번호를 표시합니다.');
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        alert('비밀번호가 틀렸습니다. 다시 입력해 주세요.');
      }
    }
  };

  if (showList !== 'my-students') return null;
  return (
    <div className='MyStudentList'>
      <div>
        <div className='my-student-list-title-wrap'>
          <div className='my-student-list-title'>학생계정 리스트</div>
        </div>
        <div className='check-student-pwd'>
          <input
            type='password'
            onChange={(e) => setPwd(e.target.value)}
            placeholder='로그인하신 계정의 비밀번호를 입력하여 공통 비밀번호를 확인하세요.'
          ></input>
          <button onClick={() => checkSession()}>공통 비밀번호 확인</button>
        </div>
        <div>
          {myStudentList.map((myStudents) => (
            <div>
              <div className='student-pwd-wrap'>
                {myStudents.schoolName} 공통 비밀번호 : <label>{isShowPwd ? myStudents.studentPwd : '********'}</label>
              </div>
              <div className='my-student-list'>
                {myStudents.students.map((studentInfo) => (
                  <div className='my-student-item'>
                    <div className='student-info'>
                      <div className='student-email-wrap'>
                        <div className='student-email'>
                          <img src={'/images/myPage/user.png'} alt='user' />
                          <label>이메일</label>
                        </div>
                        <div className='student-email-value'>{studentInfo.studentEmail}</div>
                      </div>
                      <div className='student-expire-date-wrap'>
                        <div className='student-expire-date'>
                          <img src={'/images/myPage/card.png'} alt='user' />
                          <label>사용기간</label>
                        </div>
                        <div className='student-expire-date-value'>
                          {studentInfo.expireDate.replaceAll('-', '.')}까지
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyStudentList;
