import React from 'react';
import './OrderItem.scss';
import priceFormat from '../../../lib/common/priceFormat';
import { dateFormat } from '../../../lib/common/dateFormat';
import { Link } from 'react-router-dom';
import { OrderProductInfo, OrderInfo } from 'types';

export type OrderItemProps = {
  orderInfo?: OrderInfo;
};

const OrderItem = ({ orderInfo }: OrderItemProps) => {
  return (
    <tr className='OrderItem'>
      <td className='order-date'>{dateFormat(orderInfo?.paymentDate, 'yyyy-MM-dd')}</td>
      <td className='order-seq'>
        <Link className='order-detail-link' to={`/mypage/order/detail/${orderInfo?.orderSeq}`}>
          {orderInfo?.orderSeq}
          <div className='order-detail-text'>상세보기</div>
        </Link>
      </td>
      <td className='order-product'>
        <img src={returnProductThumbnail(orderInfo?.products)} alt='product-thumbnail' />{' '}
        <div className='order-product-name'>{returnProductName(orderInfo?.products)}</div>
      </td>
      <td className='order-amt'>{priceFormat(orderInfo?.totalAmt)}원</td>
      <td className='order-progress'>{orderInfo?.orderStatusDesc}</td>
    </tr>
  );
};

const returnProductName = (productList?: OrderProductInfo[]) => {
  if (!productList) return;

  if (productList.length > 1) {
    return `${productList[0].productName} 외 ${productList.length - 1}`;
  } else {
    return `${productList[0].productName}`;
  }
};

const returnProductThumbnail = (productList?: OrderProductInfo[]) => {
  if (!productList) return;

  return productList[0].productThumbnail;
};

export default OrderItem;
