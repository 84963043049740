import React, { useState } from 'react';
import './LicenseCode.scss';
import Check from 'images/MyPage/check.png';
import { VrwareApi } from '../../../lib/api';
import { useHistory } from 'react-router-dom';

type LicenseCodeProps = {
  email?: string;
  showList: string;
};
const LicenseCode = ({ email, showList }: LicenseCodeProps) => {
  const history = useHistory();
  const [licenseCode, setLicenseCode] = useState('');

  const onChangeLicenseCode = (_licenseCode: string) => {
    setLicenseCode(_licenseCode);
  };

  const onEnterLicenseCode = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickLicenseCode();
    }
  };

  const onClickLicenseCode = async () => {
    try {
      const res = await VrwareApi.post(`/v1.0/license-codes/${licenseCode.toUpperCase()}`, { email });
      if (res.data.status_code === 200) {
        history.go(0);
        return alert('정상적으로 라이선스가 등록되었습니다.');
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };

  if (showList !== 'license') return null;

  return (
    <div className='LicenseCode'>
      <div className={`license-code-title`}>라이선스 코드 입력</div>

      <div className='license-code-input-wrap'>
        <input
          id='license-code'
          type='text'
          maxLength={8}
          onKeyPress={(e) => onEnterLicenseCode(e)}
          onChange={(e) => onChangeLicenseCode(e.target.value)}
          placeholder='라이선스 코드를 입력해 주세요.'
        />
        <div onClick={onClickLicenseCode} className='license-code-use-btn'>
          <img src={Check} alt='check' />
        </div>
      </div>
    </div>
  );
};

export default LicenseCode;
