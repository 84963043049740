import { useState } from 'react';

export default function useIsLoading() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return {
    isLoading,
    setIsLoading,
  };
}
